<template>
  <h1 class="text-h5 font-weight-light primary--text">{{ text }}</h1>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
}
</script>
