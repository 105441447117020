<template>
<v-row>
  <slot name="before-content"></slot>
  <v-col cols="12" :sm="disableAction ? 12 : 6">
    <v-card
      class="pb-4"
      :loading="deleting"
    >
      <template slot="progress">
        <v-progress-linear color="error" indeterminate />
      </template>

      <card-title class="pt-4 px-4" text="Current Associations" />
      <card-subtitle class="px-4" :text="currentAssociationText" />

      <v-divider />

      <v-virtual-scroll
        :items="associationData"
        :item-height="88"
        height="294"
      >
        <template v-slot:default="{ item }">
          <v-list-item link three-line>
            <v-list-item-content @click="pushTo(getRoute(item))">
              <v-list-item-title class="primary--text">{{ getListItemTitle(item) }}</v-list-item-title>
              <v-list-item-subtitle>{{ getListItemSubtitle(item) }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ item.dlc | simpleDate }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="!disableAction">
              <v-btn
                @click="openConfirmationDialog(DELETE, type, item)"
                icon
                large
                :disabled="saving || deleting"
              >
                <v-icon color="error">{{ DELETE_ICON }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>

      <v-divider />

    </v-card>
  </v-col>

  <v-col v-if="!disableAction" cols="12" sm="6">
    <v-card
      class="pb-4"
      :loading="saving"
    >
      <template slot="progress">
        <v-progress-linear
          color="success"
          indeterminate
        ></v-progress-linear>
      </template>

      <card-title class="pt-4 px-4" text="Add Association" />
      <card-subtitle class="px-4" :text="addAssociationText" />

      <v-text-field
        v-model="search"
        :placeholder="placeholderText"
        :prepend-icon="SEARCH_ICON"
        @keyup="$emit('search-input', search)"
        class="mx-4 mt-0 pt-0 mb-8"
        hide-details
        label="Search"
        single-line
      />

      <v-divider />

      <v-virtual-scroll
        :items="associationTargetData"
        :item-height="64"
        height="230"
      >
        <template v-slot:default="{ item }">
          <v-list-item two-line link>
            <v-list-item-content @click="pushTo(getRoute(item))">
              <v-list-item-title class="primary--text">{{ getListItemTitle(item) }}</v-list-item-title>
              <v-list-item-subtitle>{{ getAddListItemSubtitle(item) }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                @click="createFunction(type, item)"
                icon
                large
                :disabled="saving || deleting"
              >
                <v-icon color="success">{{ ADD_GENERIC_ICON }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>

      <v-divider />

      <dialog-confirmation
        :dialog.sync="dialog[CONFIRMATION].show"
        @confirmed="deleteWrapper(dialog[CONFIRMATION].config.type, dialog[CONFIRMATION].payload)"
        :config="dialog[CONFIRMATION].config"
        :payload="dialog[CONFIRMATION].payload"
      />
    </v-card>
  </v-col>
</v-row>
</template>

<script>
import { mapGetters, } from 'vuex'

import { ACTIONS, ICONS, ROUTES, DIALOGS, } from '../../constants'
import { TYPE as AGENT_TYPE } from '../../models/dto/Agent'
import { TYPE as CLIENT_TYPE } from '../../models/dto/Client'
import { TYPE as MORTGAGE_DEAL_TYPE } from '../../models/dto/MortgageDeal'
import { push } from '../../router'

import CardSubtitle from './CardSubtitle.vue'
import CardTitle from './CardTitle.vue'
import DialogConfirmation from './dialog/DialogConfirmation.vue'

export default {
  props: {
    associationData: {
      type: Array,
    },
    associationTargetData: {
      type: Array,
    },
    createFunction: {
      type: Function,
    },
    deleting: {
      type: Boolean,
      default: false,
    },
    deleteFunction: {
      type: Function,
    },
    disableAction: {
      type: Boolean,
      defaut: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String
    }
  },
  components: {
    CardSubtitle,
    CardTitle,
    DialogConfirmation,
  },
  data: () => ({
    addAssociationText: '',     // Description text for the add-association card.
    currentAssociationText: '', // Description text for the current-association card.
    placeholderText: '',
    search: '',

    // Dialog window state.
    dialog: {
      [DIALOGS.CONFIRMATION]: { show: false, config: {}, payload: {}, }
    },
  }),
  computed: {
    ...mapGetters({
      getFromClientList: 'client/getFromClientList',
      getCachedClientList: 'client/getCachedClientList',
    }),
  },
  methods: {
    deleteWrapper(type, item) {
      this.deleteFunction(type, item)
      this.dialogClose(DIALOGS.CONFIRMATION)
    },
    /**
     * Close and reset a dialog window.
     * @param {string} type Dialog window type.
     */
    dialogClose (type) {
      this.dialog[type].show = false
      this.dialog[type].config = {}
      this.dialog[type].payload = {}
    },
    /**
     * Get the route to an individual association list item based on its type.
     */
    getRoute(item) {
      switch (this.type) {
        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
          return `${ROUTES.MORTGAGE_DEALS}/${item.mortgage_deal_id}`

        case CLIENT_TYPE.CLIENT_AGENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
          return `${ROUTES.AGENTS}/${item.agent_id}`

        case AGENT_TYPE.AGENT_CLIENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
          return `${ROUTES.CLIENTS}/${item.client_id}`

        default:
          console.error(`Unsupported type: ${this.type}`)
      }
    },
    /**
     * Initialize all of the type-specific static data in the component.
     */
    initStaticData () {
      switch(this.type) {
        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
          this.addAssociationText = "Search and select a mortgage deal."
          this.currentAssociationText = "Mortgage deals associated with this agent profile."
          this.placeholderText = 'Mortgage ID or Loan Code'
          break
        
        case AGENT_TYPE.AGENT_CLIENT_ASSN:
          this.addAssociationText = "Search and select a client."
          this.currentAssociationText = "Clients associated with this agent profile."
          this.placeholderText = 'Client ID or Name'
          break

        case CLIENT_TYPE.CLIENT_AGENT_ASSN:
          this.addAssociationText = "Search and select an agent."
          this.currentAssociationText = "Agents associated with this client."
          this.placeholderText = 'Agent ID or Name'
          break

        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
          this.addAssociationText = "Search and select a mortgage deal."
          this.currentAssociationText = "Mortgage deals associated with this client."
          this.placeholderText = 'Mortgage ID or Loan Code'
          break

        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
          this.addAssociationText = "Search and select an agent."
          this.currentAssociationText = "Agents associated with this mortgage deal."
          this.placeholderText = 'Agent ID or Name'
          break

        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
          this.addAssociationText = "Search and select a client."
          this.currentAssociationText = "Clients associated with this mortgage deal."
          this.placeholderText = 'Client ID or Name'
          break

        default:
          console.error(`Unsupported type: ${this.type}`)
      }
    },
    /**
     * Initialize any constants that are used within the template.
     */
    initTemplateConstants () {
      this.CONFIRMATION = DIALOGS.CONFIRMATION
      
      this.DELETE = ACTIONS.DELETE

      this.ADD_GENERIC_ICON = ICONS.ADD_GENERIC
      this.DELETE_ICON = ICONS.DELETE
      this.SEARCH_ICON = ICONS.SEARCH
    },
    /**
     * Get the subtitle for an add-association list-item based on its type.
     */
    getAddListItemSubtitle(item) {
      switch (this.type) {
        case CLIENT_TYPE.CLIENT_AGENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
          return item.agent_name

        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
          return `Loan Code ${item.loan_code}`

        case AGENT_TYPE.AGENT_CLIENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
          return `${item.first_name} ${item.surname}`

        default:
          console.error(`Unsupported type: ${type}`)
      }
    },
    /**
     * Get the title for a list-item based on its type.
     */
    getListItemTitle(item) {
      switch (this.type) {
        case CLIENT_TYPE.CLIENT_AGENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
          return `Agent ${item.agent_id}, Profile ${item.agent_profile_id}`

        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
          return `Mortgage Deal ${item.mortgage_deal_id}`

        case AGENT_TYPE.AGENT_CLIENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
          if (!item.hasOwnProperty('is_co_applicant')) {
            item = this.getFromClientList(item.client_id)[0]
          }
          return `${item.is_co_applicant === 'Y' ? 'Co-Applicant' : 'Client'} ${item.client_id}`

        default:
          console.error(`Unsupported type: ${type}`)
      }
    },
    /**
     * Get the subtitle for a list-item based on its type.
     */
    getListItemSubtitle(item) {
      switch (this.type) {
        case CLIENT_TYPE.CLIENT_AGENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
          return item.agent_name

        case AGENT_TYPE.AGENT_CLIENT_ASSN:
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
          return item.client_name

        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
          return `Loan Code ${item.loan_code}`

        default:
          console.error(`Unsupported type: ${type}`)
      }
    },
    /**
     * Opens the confirmation dialog box and configures it based on the action and data-type being
     * confirmed.
     * @param {string} action Action to confirm.
     * @param {string} type Data-type acted upon.
     * @param {*} payload Data acted upon.
     */
    openConfirmationDialog (action, type, payload) {
      this.dialog[DIALOGS.CONFIRMATION].payload = payload

      let message = ''
      let target = ''

      switch (type) {
        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN:
          message = 'Delete this agent-mortgage deal association?'
          target = `Mortgage Deal ${payload.mortgage_deal_id}`
          break

        case AGENT_TYPE.AGENT_CLIENT_ASSN:
          message = 'Delete this agent-client association?'
          target = `Client ${payload.client_id}`
          break

        case CLIENT_TYPE.CLIENT_AGENT_ASSN:
          message = 'Delete this client-agent association?'
          target = `Agent ${payload.agent_id}, Profile ${payload.agent_profile_id}`
          break

        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
          message = 'Delete this client-mortgage deal association?'
          target = `Mortgage Deal ${payload.mortgage_deal_id}`
          break

        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
          message = 'Delete this mortgage deal-agent association?'
          target = `Agent ${payload.agent_id}, Profile ${payload.agent_profile_id}`
          break

        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
          message = 'Delete this mortgage deal-client association?'
          target = `Client ${payload.client_id}`
          break

        default:
          console.error(`Unsupported type: ${type}`)
      }

      switch (action) {
        case ACTIONS.DELETE:
          this.dialog[DIALOGS.CONFIRMATION].config = { action, type, title: 'Delete Confirmation', message, target }
          break

        default:
          console.error(`Unsupported action: ${action}`)
      }

      this.dialog[DIALOGS.CONFIRMATION].show = true
    },
    pushTo (link) {
      push(link)
    }
  },
  filters: {
    /**
     * Formats an input date string into "YYYY-MM-DD HH24:Mi:SS".
     */
    simpleDate: function (value) {
      return value.split('T').join(' ')
    }
  },
  mounted() {
    this.initStaticData()
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>