<template>
  <p class="text-subtitle-1 font-weight-light grey--text text--darken-2">{{ text }}<slot></slot></p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
}
</script>
